import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact';
//import Layout from '../components/layout';
import { useAuth } from '../components/Firebase';
import { navigate } from "gatsby"
const LoginPage = () => {
    const [formValues, setFormValues] = React.useState({ email: '', password: '' });
    const [errorMsg, setErrorMsg] = React.useState({ msg: null });
    const { firebase } = useAuth();

    function handleKeyPress(e) {
        if (e.key && e.key === "Enter") {
            handleLogin(e);
        }
    }

    function handleLogin(e) {
        e.preventDefault();
        firebase.login({ email: formValues.email, password: formValues.password }).then((data) => {
            //console.log(data);
            navigate('/admin');
        }).catch((data) => {
            if (data) {
                setErrorMsg({ msg: "Invalid Credentials" })
            }
        });
    }

    function handleInputChange(e) {
        e.persist();
        setFormValues(currentValues => ({ ...currentValues, [e.target.type]: e.target.value }))

    }

    return (
        <>
            <MDBContainer className="my-4">
                <MDBRow className="pt-4">
                    <MDBCol md="6" className="mx-auto">

                        <form className="mx-auto w-80" onSubmit={handleLogin}>
                            <p className="h5 text-center mb-4">Sign in</p>
                            {!!errorMsg && !!errorMsg.msg && <p className="h5 text-center mb-4 text-danger"> {errorMsg.msg}</p>}
                            <div className="grey-text">
                                <MDBInput
                                    label="Type your email"
                                    icon="envelope"
                                    group
                                    type="email"
                                    validate
                                    error="wrong"
                                    success="right"
                                    onChange={handleInputChange}
                                    value={formValues.email}
                                />
                                <MDBInput
                                    label="Type your password"
                                    icon="lock"
                                    group
                                    type="password"
                                    validate
                                    onChange={handleInputChange}
                                    onKeyPress={handleKeyPress}
                                    value={formValues.password}
                                />
                            </div>
                            <div className="text-center">
                                <MDBBtn onClick={handleLogin}>Login</MDBBtn>
                            </div>
                        </form>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    );
};

export default LoginPage;